import { message, Row, Form, notification } from 'antd';
import { useSession } from 'components/Session';
import React, { useEffect, useState } from 'react';
import ImageUpload from 'components/Form/ImageUploader';
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';
import { compose } from 'recompose';
import { brandUpdateAccount } from 'lib/Firebase/callables/brands';
import { trackAddPaymentInfo } from './utils';

const stripePublishKey = process.env.REACT_APP_STRIPE_PUB_KEY;

/**
 * @typedef {{
 *  form: import('antd/lib/form/Form').WrappedFormUtils
 *  stripe: import('react-stripe-elements').ReactStripeElements.StripeProps
 * }} CompanyProps
 * @type {React.FC<CompanyProps>}
 */
const Company = ({ form, stripe }) => {
  const [submitting, setSubmitting] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const { setFieldsValue, validateFieldsAndScroll } = form;
  const {
    brand: { uid: brandUID, stripeCustomerId, name, image, description, storeImage, authEmail },
  } = useSession();
  const { brand: brandDocument } = useSession();

  useEffect(() => {
    setFieldsValue({ stripeCustomerId, name, image, description, storeImage });
    if (!stripeCustomerId) {
      setShowPaymentForm(true);
    }
  }, []);

  const { getFieldDecorator } = form;

  function handleSubmit() {
    setSubmitting(true);
    validateFieldsAndScroll(async (err, fields) => {
      if (!err) {
        const params = { ...fields };
        if (showPaymentForm) {
          try {
            const stripeResponse = await stripe.createToken({ name: authEmail });
            const { token } = stripeResponse;
            if (!stripeCustomerId) {
              params.createCardTokenId = token.id;
              trackAddPaymentInfo(brandDocument);
            } else {
              params.updateCardTokenId = token.id;
            }
          } catch (e) {
            message.error('Unable to edit your card details. Try again later');
            setSubmitting(false);
            return;
          }
        }

        try {
          await brandUpdateAccount({
            ...params,
            logo: fields.storeImage,
          });

          notification.success({
            message: 'Company',
            description: 'Company settings saved.',
            duration: 3,
          });
          setShowPaymentForm(false);
        } catch (e) {
          const feedbackMessage =
            String(e.response.data.message) || String(e.message) || 'Something went wrong';
          notification.error({
            message: 'Error',
            description: feedbackMessage,
            duration: 3,
          });
        }
      }

      setSubmitting(false);
    });
  }

  return (
    <Form form={form} layout="vertical" className="product-form pb-8" hideRequiredMark>
      <Form.Item>
        {getFieldDecorator('storeImage', {
          rules: [{ required: true, message: 'Required' }],
        })(
          <ImageUpload filename={brandUID}>
            <div className="label">Add any image that represents your brand</div>
          </ImageUpload>
        )}
      </Form.Item>
      <Form.Item label="Company Name">
        {getFieldDecorator('name', { rules: [{ required: true, message: 'Required' }] })(
          <input
            className="notifications-input-field"
            defaultValue=""
            name="name"
            placeholder="Trend"
            type="text"
          />
        )}
      </Form.Item>
      <Form.Item label="Company Description">
        {getFieldDecorator('description', {
          rules: [{ required: true, message: 'Required' }],
        })(
          <textarea
            className="notifications-input-field"
            placeholder="Describe your company in 2-3 sentences."
            rows="5"
          />
        )}
      </Form.Item>
      <div className="notifications-floating-footer">
        <button
          className={`notifications-update-button ${
            submitting ? 'notifications-update-button--loading' : ''
          }`}
          onClick={handleSubmit}
          type="button"
          disabled={submitting}
        >
          {submitting && (
            <i className="anticon anticon-loading">
              <svg
                viewBox="0 0 1024 1024"
                focusable="false"
                className="anticon-spin"
                width="1em"
                height="1em"
                fill="currentColor"
              >
                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
              </svg>
            </i>
          )}
          <span>Save Updates</span>
        </button>
      </div>
    </Form>
  );
};

const Wrapper = compose(
  injectStripe,
  Form.create({ name: 'brand-account-edit' })
)(Company);

export const CompanyTab = () => {
  return (
    <div style={{ background: '#000 !important' }}>
      <Row type="flex" justify="space-around">
        <div className="min-layout-content small">
          <StripeProvider apiKey={stripePublishKey}>
            <Elements>
              <Wrapper />
            </Elements>
          </StripeProvider>
        </div>
      </Row>
    </div>
  );
};

export default CompanyTab;
