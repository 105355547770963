export const CREATOR_CATEGORIES = {
  all: 'All',
  wellness: 'Wellness',
  food: 'Food',
  beverages: 'Beverages',
  fashion: 'Fashion',
  beauty: 'Beauty',
  adventure: 'Adventure',
  home: 'Home',
  fitness: 'Fitness',
  pet: 'Pet',
  technology: 'Technology',
  travel: 'Travel',
  other: 'Other',
};
