// INSTRUCTIONS
//
// ROUTE USAGE FORMAT
// format: toPathName
// ex
//   this.props.history.push(toCampaignSummaryPath(campaignId))
//
// ROUTE DECLERATION FORMAT
// format: NAME_OF_PATH
// ex.
//   <Route to={CAMPAIGN_SUMMARY_PATH} />
//
// NOTE: Always add a 'PATH' suffix to the path.
// ex.
//   - toPostListPath
//   - POST_LIST_PATH
//

/**
 * Next Paths -> redirect to web-client-brands
 */
const buildNextAppRedirect = pathname => {
  const { search } = window.location;
  const nextAppUrl = new URL(pathname, process.env.REACT_APP_NEXT_APP_URL);
  nextAppUrl.search = search;
  return nextAppUrl;
};

export const toAuth = () => {
  window.location.assign(buildNextAppRedirect('/'));
};

export const toNewCampaign = campaignType => {
  const redirect = buildNextAppRedirect('/campaigns/create');
  if (campaignType) {
    redirect.searchParams.append('type', campaignType);
  }
  window.location.assign(redirect);
};
export const toEditCampaign = campaignUID => {
  const redirect = buildNextAppRedirect(`/campaigns/update/${campaignUID}`);
  window.location.assign(redirect);
};
export const toAddCredits = () => {
  const redirect = buildNextAppRedirect('/credits/add-credits');
  window.location.assign(redirect);
};
export const toContentCollection = (campaignId, creatorId) => {
  const redirect = buildNextAppRedirect(`/content/${campaignId}/${creatorId}`);
  window.location.assign(redirect);
};

export const SETUP_STORE_PATH = '/setup/store';

// export const MANAGE_CREDITS_PATH = '/credits';

export const ADMIN_OPTIONS_PATH = '/admin';
export const ADMIN_BRANDS_PATH = '/admin/brands';
export const ADMIN_BRAND_PATH = '/admin/brands/:id';

// demo path
export const DEMO_MESSAGES_PATH = '/campaigns/:campaignId/demo/messages';
export const DEMO_MY_INFLUENCERS_PATH = '/campaigns/:campaignId/demo/my_influencers';
export const DEMO_POSTS_PATH = '/campaigns/:campaignId/demo/posts';

/**
 *  ADMIN V2
 */
export const ADMINV2_PATH = '/adminv2';
export const ADMINV2_ACTIVE_CAMPAIGNS_PATH = `${ADMINV2_PATH}/activeCampaigns`;
export const ADMINV2_ACTIVE_CAMPAIGN_PATH = `${ADMINV2_PATH}/activeCampaigns/:id`;
export const ADMINV2_ACTIVE_INFLUENCERS_PATH = `${ADMINV2_PATH}/influencers`;
export const ADMINV2_BRANDS_PATH = `${ADMINV2_PATH}/brands`;
export const ADMINV2_BRAND_PATH = `${ADMINV2_PATH}/brands/:brandUID`;
export const ADMINV2_REVIEWS_PATH = `${ADMINV2_PATH}/reviews`;
export const ADMINV2_REFERRALS_PATH = `${ADMINV2_PATH}/referrals`;
export const ADMINV2_PAYOUTS_PATH = `${ADMINV2_PATH}/payouts`;
export const ADMINV2_ONBROADING_GUIDES_PATH = `${ADMINV2_PATH}/onboardingGuides`;
export const ADMINV2_BUSINESS_APPROVAL_PATH = `${ADMINV2_PATH}/businessApproval`;
export const ADMINV2_EXPIRED_CREDITS_PATH = `${ADMINV2_PATH}/expiredCredits`;
export const ADMINV2_PENDING_INFLUENCERS_PATH = `${ADMINV2_PATH}/pendingInfluencers`;
export const ADMINV2_SUBSCRIPTIONS_PATH = `${ADMINV2_PATH}/subscriptions`;
export const ADMINV2_API_QUEUES = `${ADMINV2_PATH}/apiQueues`;
export const ADMINV2_API_SHOW_QUEUE = `${ADMINV2_PATH}/apiQueues/:api`;
export const toAdminV2ApiShowQueuePath = api => `${ADMINV2_PATH}/apiQueues/${api}`;
export const ADMINV2_CONTENTS_PATH = `${ADMINV2_PATH}/contents`;

/**
 * CAMPAIGNS Paths
 */
export const CONTENT_SHOWCASE_PATH = `/campaigns/showcase`;
export const CAMPAIGN_PATH = '/campaigns/:campaignId';
export const CAMPAIGN_SUMMARY_PATH = `${CAMPAIGN_PATH}/summary`;
export const CAMPAIGN_APPLICATION_PATH = `${CAMPAIGN_PATH}/applications`;
export const CAMPAIGN_CREATORS_PATH = `${CAMPAIGN_PATH}/creators`;
export const CAMPAIGN_GALLERY_PATH = `${CAMPAIGN_PATH}/gallery`;
export const CAMPAIGN_CREATORS_LIST_PATH = `${CAMPAIGN_CREATORS_PATH}/list`;
export const toCampaignCreatorsListPath = campaignId => `/campaigns/${campaignId}/creators/list`;
// TODO: Why are the shipments paths different?
export const CAMPAIGN_CREATORS_SHIPMENTS_PATH = `${CAMPAIGN_CREATORS_PATH}/shipments`;
export const toCampaignCreatorsShipmentsPath = campaignId =>
  `/campaigns/${campaignId}/creators/shipments`;
//
export const CAMPAIGN_MESSAGES_PATH = `${CAMPAIGN_PATH}/messages`;
export const toCampaignMessagesPath = campaignId => `/campaigns/${campaignId}/messages`;
export const toCampaignMessagePath = (campaignId, id) => `/campaigns/${campaignId}/messages/${id}`;
export const CAMPAIGN_HISTORY_PATH = '/campaigns/history';
export const CAMPAIGN_APPROVALS_PATH = `${CAMPAIGN_PATH}/approvals`;
export const CAMPAIGN_APPROVALS_PENDING_PATH = `${CAMPAIGN_PATH}/approvals/pending`;
export const CAMPAIGN_APPROVALS_COMPLETED_PATH = `${CAMPAIGN_PATH}/approvals/completed`;
export const CAMPAIGN_APPROVALS_COMPLETED_MEDIA_PATH = `${CAMPAIGN_PATH}/approvals/completed/media`;
export const CAMPAIGN_APPROVALS_PENDING_CONTENT_PATH = `${CAMPAIGN_PATH}/approvals/pending_content`;
export const toCampaignSummaryPath = campaignId => `/campaigns/${campaignId}/summary`;
export const toCampaignApprovalPath = campaignId => `/campaigns/${campaignId}/approvals`;
export const toCampaignApprovalPendingPath = campaignId =>
  `${toCampaignApprovalPath(campaignId)}/pending`;
export const toCampaignApprovalCompletedPath = campaignId =>
  `${toCampaignApprovalPath(campaignId)}/completed`;
export const toCampaignApprovalPendingContentPath = campaignId =>
  `${toCampaignApprovalPath(campaignId)}/pending_content`;

export const toCampaignApprovalCompletedMediaPath = campaignId =>
  `${toCampaignApprovalPath(campaignId)}/completed/media`;

/**
 * EXAMPLE CAMPAIGNS Path
 */
export const toCampaignExample = campaignUID => `/exampleCampaigns/${campaignUID}/summary`;
export const EXAMPLE_CAMPAIGNS_PATH = '/exampleCampaigns';
export const EXAMPLE_CAMPAIGN_PATH = '/exampleCampaigns/:campaignId';
export const SHOW_EXAMPLE_CAMPAIGN_PATH = `${EXAMPLE_CAMPAIGNS_PATH}/:campaignId`;

/**
 * ACCOUNTS PATH
 */
export const ACCOUNT_PATH = '/account';
export const ACCOUNT_EDIT_PATH = '/account/company';
export const ACCOUNT_CHANGE_PASSWORD = '/account/change_password';
export const ACCOUNT_UPDATE_EMAIL_PATH = '/account/update_email';
export const ACCOUNT_TOS_PATH = '/account/tos';
export const ACCOUNT_PRIVACY_PATH = '/account/privacy';
export const ACCOUNT_EMAIL_NOTIFICATIONS_PATH = '/account/email-notifications';
export const ACCOUNT_SUBSCRIPTION_SETTINGS_PATH = '/account/subscription';

/**
 * PUBLIC PATHS
 */
export const PUBLIC_SIGNUP_PATH = '/signup';
export const PUBLIC_LOGIN_PATH = '/login';
export const PUBLIC_FORGOT_PASSWORD = '/forgot_password';
export const PUBLIC_SHOWCASE = '/showcase';

/**
 * OTHER PATHS
 */
export const SUCCESS_PURCHASE_PATH = '/success-purchase';
export const POSTS_PATH = '/posts';
export const FREE_CREDITS_PATH = '/free-credits';
export const SOONA_SHOWCASE = '/soona-showcase';
