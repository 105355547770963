import React, { useState } from 'react';
import { notification } from 'antd';
import Product, { useProductData } from 'components/Products';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'stores';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PrefetchCreatorProfilePages } from 'components/Influencers/Drawer/CreatorProfileDrawer';
import { brandRemovePartnership } from 'api/partnerships';

/**
 * @typedef {{}} CreatorListProps
 * @type {React.FC<CreatorListProps>}
 */
const CreatorList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { params } = useRouteMatch();

  const [rejectModal, setRejectModal] = useState({
    partnershipUID: null,
    show: false,
  });

  const { approvedPartnershipUIDs, messageUIDs, invitedInfluencerUIDs } = useProductData();
  const partnerships = useSelector(selectors.getPartnerships());
  const messages = useSelector(selectors.getMessages());
  const influencers = useSelector(selectors.getInfluencers());

  const { productUID } = useProductData();

  function handleInfluencerRemove(partnershipUID) {
    setRejectModal(state => ({ ...state, show: true, partnershipUID }));
  }

  async function handleConfirmReject(partnershipUID, rejectMessage) {
    try {
      await brandRemovePartnership(productUID, partnershipUID, rejectMessage);
      dispatch(
        actions.productContexts.removeProductApprovedPartnerships(productUID, [partnershipUID])
      );

      notification.success({
        message: 'Rejected',
        description: 'Creator successfully rejected!',
        duration: 2,
      });
    } catch (e) {
      const feedbackMessage =
        String(e.response.data.message) ||
        String(e.message) ||
        'Something went wrong on accepting influencer';
      notification.error({
        message: 'Error',
        description: feedbackMessage,
        duration: 4,
      });
    }
  }

  function handleViewMessage(partnershipUID) {
    const { influencerUID } = partnerships[partnershipUID];
    const [messageUID] = messageUIDs.filter(x => messages[x].users[influencerUID]);
    history.push(`/campaigns/${params.campaignId}/messages/${messageUID}`);
  }

  function handleViewProfile(influencerUID) {
    const { creatorUsername, creatorId } = influencers[influencerUID];
    const profileLink = `${process.env.REACT_APP_CREATOR_WEB_URL}/profile/${creatorUsername ||
      creatorId}`;

    window.open(profileLink, '_blank');
  }

  return (
    <div className="trend-table--wrapper">
      <Product.Creators
        partnershipUIDs={approvedPartnershipUIDs}
        onInfluencerRemove={handleInfluencerRemove}
        onViewMessage={handleViewMessage}
        onViewProfile={influencerUID => handleViewProfile(influencerUID)}
      />
      <Product.RejectCreatorModal
        {...rejectModal}
        onClose={() => setRejectModal(s => ({ ...s, show: false }))}
        onConfirm={handleConfirmReject}
      />
      <PrefetchCreatorProfilePages influencerUIDs={invitedInfluencerUIDs} />
    </div>
  );
};

export default CreatorList;
