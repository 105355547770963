import React from 'react';
import { Menu, Badge } from 'antd';
import { useLocation, useRouteMatch, Link } from 'react-router-dom';
import { useProductData } from 'components/Products';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { toCampaignCreatorsShipmentsPath, toCampaignCreatorsListPath } from 'constants/routes';
import Container from 'components/Common/Container';

/**
 * @typedef {{}} CreatorTabsProps
 * @type {React.FC<CreatorTabsProps>}
 */
const Tabs = () => {
  const { params } = useRouteMatch();
  const location = useLocation();
  let defaultKey = 'shipments';
  const arrPathname = location.pathname.split('/');
  const pathIndex = location.pathname.includes('demo') ? 5 : 4;
  const { approvedPartnershipUIDs } = useProductData();
  const partnerships = useSelector(selectors.getPartnerships());
  const needsShippingCount = approvedPartnershipUIDs.filter(
    x => partnerships[x].status === 'needsShipping'
  ).length;

  const shippedCount = approvedPartnershipUIDs.length - needsShippingCount;

  defaultKey = [arrPathname[pathIndex] || defaultKey];

  return (
    <div className="campaign-subtabs">
      <Container>
        <Menu mode="horizontal" defaultSelectedKeys={defaultKey}>
          <Menu.Item className="campaign-subtab shipment" key="shipments" style={{ width: '50%' }}>
            <Link to={toCampaignCreatorsShipmentsPath(params.campaignId)}>
              <div className="campaign-subtab-icon">
                <Badge
                  count={needsShippingCount}
                  offset={[10, 0]}
                  style={{ backgroundColor: '#ffb917' }}
                />
              </div>
              <div className="campaign-subtab-details">
                <div className="campaign-subtab-details-count">
                  {shippedCount}/{approvedPartnershipUIDs.length} Sent
                </div>
                <div className="campaign-subtab-details-label">SHIPMENTS MADE</div>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item
            className="campaign-subtab creators"
            key="creators"
            style={{ width: '50%', textAlign: 'center' }}
          >
            <Link to={toCampaignCreatorsListPath(params.campaignId)}>
              <div className="campaign-subtab-icon" />
              <div className="campaign-subtab-details">
                <div className="campaign-subtab-details-count">
                  {approvedPartnershipUIDs.length} Creators
                </div>
                <div className="campaign-subtab-details-label">CREATOR LIST</div>
              </div>
            </Link>
          </Menu.Item>
        </Menu>
      </Container>
    </div>
  );
};

export default Tabs;
