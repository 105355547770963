import React from 'react';
import { Menu, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { PLACEHOLDER_IMG_URL } from 'constants/common';

const CampaignMenu = props => {
  const { campaignId, image, title, onNavigate, ...others } = props;
  const imageSrc =
    image && image !== PLACEHOLDER_IMG_URL ? image : '/images/campaign-placeholder.png';

  return (
    <Menu.Item {...others}>
      <Link to={`/campaigns/${campaignId}/summary`} className="campaign-link" onClick={onNavigate}>
        <Avatar src={imageSrc} shape="square" size={36} className="campaign-avatar" />
        <p className="campaign-item">{title}</p>
      </Link>
    </Menu.Item>
  );
};

export default CampaignMenu;
