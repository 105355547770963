import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { selectors } from 'stores';

const InfluencerTable = ({
  setLastLoginDateSorter,
  influencerUIDs = [],
  columns = [],
  ...rest
}) => {
  const influencers = useSelector(selectors.getInfluencers());
  const [source, setSource] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const tableChange = (pagination, filters, { field, order, data = [] }) => {
    const dataTmp = data.length ? data : source;
    const sourceTmp = dataTmp.sort((a, b) => {
      const aField = a[field] || 0;
      const bField = b[field] || 0;

      if (order === 'ascend') return aField - bField;
      if (order === 'descend') return bField - aField;
      return 0;
    });

    if (field === 'lastLoginDate') {
      setLastLoginDateSorter(order);
    }

    setSortField(field);
    setSortOrder(order);
    setSource(sourceTmp);
  };

  useEffect(() => {
    const sourceTmp = influencerUIDs.map(influencerUID => {
      const {
        createdAt,
        engagementRate = 0,
        avgLikeCount = 0,
        avgCommentCount = 0,
        followedByCount = 0,
        applicationCount = 0,
        completedPartnershipCount = 0,
        activePartnershipCount = 0,
        lastAppliedDate,
        isVIP,
        isSuspended,
        username,
        parsedState,
        name,
        image,
        star = 0,
        lastLoginDate,
        avgAttitudeProfessionalism = 0,
        avgContentQuality = 0,
        overduePartnershipUIDs = [],
      } = influencers[influencerUID];

      return {
        key: influencerUID,
        influencerUID,
        createdAt,
        engagementRate,
        avgLikeCount,
        avgCommentCount,
        followedByCount,
        applicationCount,
        activePartnershipCount,
        completedPartnershipCount,
        lastAppliedDate,
        star,
        username,
        parsedState,
        name,
        isVIP,
        isSuspended,
        image,
        lastLoginDate,
        avgAttitudeProfessionalism,
        avgContentQuality,
        overduePartnershipUIDs,
      };
    });

    setSource(sourceTmp);

    tableChange({}, {}, { field: sortField, order: sortOrder, data: sourceTmp });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [influencerUIDs]);

  return (
    <Table
      dataSource={source}
      pagination={false}
      columns={columns}
      onChange={tableChange}
      {...rest}
    />
  );
};

export default InfluencerTable;
