import React from 'react';

/**
 *
 * @typedef {{text: string,
 *  tabClick: (text) => any,
 *  selected: boolean,
 * }} NavButtonProps
 * @type {React.FC<NavButtonProps>}
 */

const NavButton = ({ text, tabClick, selected }) => {
  const classname = selected ? 'u-button-reset nav-button selected' : 'u-button-reset nav-button';
  return (
    <button className={classname} onClick={() => tabClick(text)}>
      {text}
    </button>
  );
};

export default NavButton;
