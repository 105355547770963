import React from 'react';
import FAQCard from './FAQCard';

/**
 * @typedef {{
 *  isMobile: boolean,
 *  faqs?: string[],
 * }} FAQSectionProps
 *
 * @type {React.FC<FAQSectionProps>}
 */

let faqArray = [
  {
    question: 'What is Trend?',
    answer: (
      <p>
        Trend is a self-serve content platform. Most customers love using Trend to create photos &
        videos for their online stores, social media content, and/or paid ads. With our diverse and
        hand-selected creator network, Trend allows companies to get content they need to relate to
        their audiences and drive sales.
      </p>
    ),
  },
  {
    question: 'How do I get started?',
    answer: (
      <>
        <p>
          We are genuinely excited about what we’ve built and can’t wait to work with you. Click
          here to start a project. Below is the sequence of events.
        </p>
        <ol>
          <li>Create a project</li>
          <li>Review & approve content creator applications</li>
          <li>Ship your products to content creators</li>
          <li>Collab with creators with in-platform messaging and plan content delivery dates.</li>
          <li>
            Approve & download content to use where you need it with complete licensing rights
          </li>
        </ol>
      </>
    ),
  },
  {
    question: 'How does pricing work?',
    answer: (
      <>
        Creator credits are $5.50*
        <p>
          Hiring creators can cost 20, 40, or 60 credits based on their skill set. Each creator you
          hire will produce 5 photos or 2 videos.
          <br />
        </p>
        <p>
          Only pay for what you need. No subscriptions, software fees, or contracts. Hire who you
          want, when you want.
          <br />
        </p>
        <p>
          We’ve pre-negotiated pay rates with every creator in our network, saving you time, money,
          and stress of negotiation.
          <br />
        </p>
        <p>* Price per creator decreases when more creator credits are purchased up front.</p>
      </>
    ),
  },
  {
    question: 'Who creates the content?',
    answer: (
      <>
        <p>
          We have personally built a network of content creators. Every type of person: race,
          gender, shape, size, age, interests etc. can be found in our network. These people are
          passionate photographers and videographers who will read your project outline and apply to
          work with you.
          <br />
        </p>
        <p>
          The price of content is guaranteed. You will not pay if a creator does not produce. Each
          creator will produce 5 images or 2 videos depending on your project request.
        </p>
      </>
    ),
  },
  {
    question:
      'How can I best utilize custom content? Can you help me understand what my brand needs most?',
    answer: (
      <p>
        We are a self-serve platform that will set you up for success. We believe in educating our
        customers so they can become entirely confident and kick-ass at their jobs. If you’d like to
        ask us a question, we’re available via chat and will always make ourselves available to
        speak. Schedule time with us to help us learn more about your brand so we can help you win.
      </p>
    ),
  },
  {
    question: 'How much control do I have over the content produced?',
    answer: (
      <p>
        The brands who have the most success on Trend are collaborative in philosophy. You’ll be
        able to choose the category of content you need and outline key elements you need to see
        executed on. It’s important to remind yourself of the reasons you are drawn to the creators
        you choose to work with. Be sure your ideas are infused into their creative process without
        taking over. That will generate the most authentic and impactful photos and videos by a long
        shot. Collaborate and have fun!
      </p>
    ),
  },
];

const FAQSection = ({ isMobile, faqs }) => {
  if (faqs) {
    faqArray = faqs;
  }
  return (
    <div className={`${isMobile ? 'faq-section mobile' : 'faq-section'}`}>
      <div id="faq-subsection" className="faq-inner">
        <h4 id="faq-title" className="faq-title">
          You Might Be Asking...
        </h4>
        <ul>
          {faqArray.map(item => (
            <li key={item.question}>
              <FAQCard question={item.question} answer={item.answer} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FAQSection;
