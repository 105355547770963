import { Button } from 'antd';
import React, { useState } from 'react';

/**
 * Product Settings Reactivate Component
 *
 * @typedef {{ onClick: () => Promise<void>}} ReactivateProps
 * @type {React.FC<ReactivateProps>}
 */
const Reactivate = ({ onClick }) => {
  const [submitting, setSubmitting] = useState(false);

  async function handleConfirm() {
    setSubmitting(true);
    await onClick();
    setSubmitting(false);
  }

  return (
    <>
      <Button
        key="revert"
        loading={submitting}
        onClick={handleConfirm}
        className="trend-btn secondary"
        style={{ borderColor: '#a9b0b9' }}
      >
        <span className="hidden-xmobile">Reactivate Campaign</span>
      </Button>
    </>
  );
};

export default Reactivate;
