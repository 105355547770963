import { useSession } from 'components/Session';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { toEditCampaign } from 'constants/routes';
import useProductData from '../useProductData';
import Unlist from './Unlist';
import Relist from './Relist';
import Archive from './Archive';
import Reactivate from './Reactivate';

const styles = StyleSheet.create({
  container: {
    background: '#f5f9fe',
    borderTop: '1px solid #dbdfe8',
    bottom: '0',
    padding: '0.75rem 1.5rem',
    position: 'fixed',
    right: '0',
    width: '100%',
    '@media (min-width: 48rem)': {
      padding: '0.75rem',
      width: 'calc(100% - 15rem)',
    },
  },
});

/**
 * @typedef {{
 * onArchive: () => Promise<void>,
 * onRelist: () => Promise<void>,
 * onUnlist:  () => Promise<void>,
 * onReactivate: () => Promise<void>
 * }} SettingsProps
 * @type {React.FC<SettingsProps>}
 */
const Settings = ({ onArchive, onReactivate, onRelist, onUnlist }) => {
  const { productUID } = useProductData();
  const { brand } = useSession();
  const { status, lastPurchaseDate } = brand;
  const { timestampCompleted, isListed, type } = useSelector(selectors.getProduct(productUID));
  const showMarqueNotif = status === 'approved' && !lastPurchaseDate;
  const onBoardingCompleted = status === 'approved' && lastPurchaseDate;
  const isCompleted = !!timestampCompleted;

  const actionItems = [];
  if (!status || onBoardingCompleted) {
    if (!isCompleted) {
      if (isListed) {
        actionItems.push(<Unlist key="remove-listing" onClick={onUnlist} />);
      } else {
        actionItems.push(<Relist key="add-listing" onClick={onRelist} />);
      }

      actionItems.push(<Archive key="archive" onClick={onArchive} />);
    } else {
      actionItems.push(<Reactivate key="reactivate" onClick={onReactivate} />);
    }
  }

  const handleEditCampaign = () => {
    toEditCampaign(productUID);
  };

  return (
    <div
      className={`${css(styles.container, styles.notFree)} ${
        showMarqueNotif ? 'with-notification' : ''
      }`}
    >
      <div className="campaign-footer">
        <Button
          onClick={handleEditCampaign}
          style={{ borderColor: '#a9b0b9' }}
          className="trend-btn secondary"
          disabled={type === 'social'}
        >
          Edit<span className="hidden-xmobile">&nbsp;Campaign</span>
        </Button>
        {actionItems}
      </div>
    </div>
  );
};

export default Settings;
