import React from 'react';
import moment from 'moment';
import { Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { ReactComponent as IconSettings } from 'images/icon-settings.svg';
import * as routes from 'constants/routes';
import { withCookies } from 'react-cookie';
import { useSession } from 'components/Session';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import InitalSideMenu from './InitalSideMenu';
import CampaignsSideMenu from './CampaignsSideMenu';
import ContentShowcaseTab from '../ContentShowcaseTab';
import SoonaShowcaseTab from '../SoonaShowcaseTab';

const CampaignMenus = ({ showContentShowcase, onNavigate }) => {
  const {
    brand,
    activeProductUIDs: activeProducts = [],
    completedProductUIDs: completedProducts = [],
  } = useSession();
  const products = useSelector(selectors.getProducts());
  const ownedCampaigns = activeProducts;
  const noCampaigns = activeProducts.length === 0 && completedProducts.length === 0;
  const { isAdmin, isContentAdmin } = brand;
  const { status, stripeCustomerId, lastPurchaseDate, approvedAt } = brand;
  const incomplete = !stripeCustomerId;
  const firstCampaign = products[ownedCampaigns[0]];
  const firstCampaignCompleted = firstCampaign && !incomplete;
  const onBoarding = status && !lastPurchaseDate;
  const onBoardingApproved = onBoarding && status === 'approved';
  const onBoardingApprovedComplete = onBoardingApproved && firstCampaignCompleted;
  const showMarqueNotif = status === 'approved' && !lastPurchaseDate;

  const onBoardingActive =
    onBoardingApprovedComplete &&
    moment(approvedAt, 'YYYY-MM-DD HH:mm:SS ZZ').add(24, 'hours') > moment();

  const expiredOnBoardingCampaign =
    onBoardingApprovedComplete &&
    moment(approvedAt, 'YYYY-MM-DD HH:mm:SS ZZ').add(24, 'hours') < moment();

  return (
    <div className="side-menu sidenav-main-wrapper">
      <div className="sidenav-campaigns">
        <div>
          {noCampaigns ? <InitalSideMenu /> : <CampaignsSideMenu onNavigate={onNavigate} />}
        </div>
        {!!completedProducts.length && (
          <div id="archive-link-container">
            <Link to="/campaigns/history" className="sub-menu-btn archive" onClick={onNavigate}>
              <span>Archived Projects</span>
            </Link>
          </div>
        )}
      </div>

      <ul
        className={`sidenav-footer ${(expiredOnBoardingCampaign || onBoardingActive) &&
          showMarqueNotif &&
          'with-notification'}`}
      >
        {(isAdmin || isContentAdmin) && (
          <li key="admin" className="showcase-tab">
            <Link to="/admin" className="showcase-link" onClick={onNavigate}>
              <span className="link-wrapper">
                <span className="box-border" style={{ color: '#FFF', marginRight: '0' }}>
                  <Icon type="user" style={{ width: '25px', height: '25px', fontSize: '18px' }} />
                </span>
                <span className="tab-link-text">Access Employee Functions</span>
              </span>
            </Link>
          </li>
        )}
        <li key="campaigns/showcase">
          <ContentShowcaseTab isActive={showContentShowcase} onClick={onNavigate} />
        </li>
        <li>
          <SoonaShowcaseTab onClick={onNavigate} />
        </li>
        <li className="showcase-tab">
          <a
            className="showcase-link"
            target="_blank"
            href="https://soona.co/mokker?utm_source=trend&utm=referral&utm_campaign=photo_ai_tools&utm_content=trend_side_nav"
            rel="noopener noreferrer"
            onClick={onNavigate}
          >
            <span className="link-wrapper">
              <span className="flex-row-between">
                <span className="sidenav-link-left">
                  <span className="box-border" style={{ color: '#FFF', marginRight: '0' }}>
                    <Icon type="tool" style={{ width: '25px', height: '25px', fontSize: '18px' }} />
                  </span>
                  <span className="tab-link-text">Photo AI Tools</span>
                </span>
                <span className="new-pill" style={{ marginRight: '1rem' }}>
                  NEW
                </span>
              </span>
            </span>
          </a>
        </li>
        <li className="showcase-tab">
          <a
            className="showcase-link"
            target="_blank"
            href="https://soona.co/packs?utm_source=trend&utm=referral&utm_campaign=studio_bookings&utm_content=trend_side_nav"
            rel="noopener noreferrer"
            onClick={onNavigate}
          >
            <span className="link-wrapper">
              <span className="flex-row-between">
                <span className="sidenav-link-left">
                  <span className="box-border" style={{ color: '#FFF', marginRight: '0' }}>
                    <Icon
                      type="camera"
                      style={{ width: '25px', height: '25px', fontSize: '18px' }}
                    />
                  </span>
                  <span className="tab-link-text">Studio Bookings</span>
                </span>
                <span className="new-pill" style={{ marginRight: '1rem' }}>
                  NEW
                </span>
              </span>
            </span>
          </a>
        </li>
        <li className="showcase-tab">
          <a
            className="showcase-link"
            target="_blank"
            href="https://support.soona.co/trend-ugc"
            rel="noopener noreferrer"
            onClick={onNavigate}
          >
            <span className="link-wrapper">
              <span
                className="box-border"
                style={{
                  color: '#FFF',
                  fontSize: '22px',
                  fontWeight: '600',
                  lineHeight: '34px',
                  marginRight: '0',
                }}
              >
                ?
              </span>
              <span className="tab-link-text">Help Center</span>
            </span>
          </a>
        </li>
        <li key="store/edit" className="showcase-tab">
          <Link to={routes.ACCOUNT_PATH} className="showcase-link" onClick={onNavigate}>
            <span className="link-wrapper">
              <span className="box-border" style={{ color: '#FFF', marginRight: '0' }}>
                <Icon component={IconSettings} className="sidenav-icon-account-settings" />
              </span>
              <span className="tab-link-text">Account Settings</span>
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default compose(
  withRouter,
  withCookies
)(CampaignMenus);
