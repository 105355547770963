import { Button, Col, Row } from 'antd';
import * as routes from 'constants/routes';
import React from 'react';
import mixpanel from 'lib/Analytics/Mixpanel';

const ContentCredit = ({ standardCredits }) => {
  function goTo() {
    if (mixpanel) {
      mixpanel.track('Brand Visits Add Credits');
    }
    routes.toAddCredits();
  }

  return (
    <Row>
      <Col lg={30} className="credit-title-block" style={{ paddingTop: '0' }}>
        <div>
          <span className="credit-value">{standardCredits || 0}</span>
          <div className="credit-title">Creator Credits</div>
          <p className="credit-desc">Add credits to hire creators</p>
        </div>
        <Button className="credit-add" type="primary" block onClick={goTo}>
          add creator credits
        </Button>
      </Col>
    </Row>
  );
};

export default ContentCredit;
