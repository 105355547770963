import React, { useState } from 'react';
import { Prompt, useHistory, withRouter } from 'react-router-dom';
import { Button, Col, Modal, Row, notification } from 'antd';
import { useSession } from 'components/Session';
import Container from 'components/Common/Container';
import { brandUpdateAccount } from 'lib/Firebase/callables/brands';
import { ReactComponent as CheckIcon } from 'images/components/Products/Form/CreativeBrief/CheckIcon.svg';
import { ReactComponent as CloseXSmall } from 'images/components/Form/TagSelectV2/CloseXSmall.svg';

const validateEmailAddress = email => {
  const input = document.createElement('input');

  if (email.lastIndexOf('.') < email.lastIndexOf('@')) {
    return false;
  }

  input.type = 'email';
  input.required = true;
  input.value = email;

  return typeof input.checkValidity === 'function'
    ? input.checkValidity()
    : /\S+@\S+\.\S+/.test(email);
};

const formatEmailAddress = email =>
  `${email.substring(0, 1).toUpperCase()}${email.substring(1, email.length).toLowerCase()}`;

const Checkbox = ({ active, handleClick }) => {
  if (active) {
    return (
      <button
        className="notifications-checkbox notifications-checkbox--active"
        onClick={handleClick}
        type="button"
      >
        <CheckIcon />
      </button>
    );
  }

  return <button className="notifications-checkbox" onClick={handleClick} type="button" />;
};

const EmailButton = ({ authEmail, email, handleClick }) => {
  if (email === authEmail) {
    return (
      <div className="notifications-email notifications-email--primary">
        {formatEmailAddress(email)}
      </div>
    );
  }

  return (
    <button className="notifications-email" onClick={() => handleClick(email)} type="button">
      <span>{formatEmailAddress(email)}</span>
      <CloseXSmall />
    </button>
  );
};

const emailInitialState = {
  contentApprovals: true,
  newApplications: true,
};

/**
 * @typedef {{}} NotificationsProps
 * @type {React.FC<NotificationsProps>}
 */
const Notifications = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {
    brand: { authEmail, emails: accountEmails },
  } = useSession();
  const [emailInput, setEmailInput] = useState('');
  const [emails, setEmails] = useState(
    accountEmails || [
      {
        ...emailInitialState,
        email: authEmail,
      },
    ]
  );
  const [changes, setChanges] = useState(false);
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const handleBlockedNavigation = (location, action) => {
    setUnsavedChangesModal({ visible: changes, location, action });
    return !changes;
  };

  const handleProceedAndDiscardChanges = async (action, location) => {
    await setChanges(false);
    if (action === 'REPLACE') {
      history.replace(location.pathname);
    } else if (action === 'PUSH') {
      history.go(location.pathname);
    } else if (action === 'POP') {
      history.pop();
    }
  };

  const saveChanges = async () => {
    try {
      setLoading(true);

      notification.info({
        message: 'Saving changes...',
        duration: 1,
      });

      await brandUpdateAccount({
        emails,
      });

      setLoading(false);
      setChanges(false);

      notification.success({
        message: 'Changes saved!',
        duration: 3,
      });
    } catch (err) {
      const feedbackMessage =
        String(err.response.data.message) || String(err.message) || 'Something went wrong';
      notification.error({
        message: 'Error',
        description: feedbackMessage,
        duration: 3,
      });
    }
  };

  const handleInputEmail = e => {
    setChanges(true);
    setEmailInput(e.target.value);
  };
  const handleAddEmail = () => {
    if (emailInput.trim().length && validateEmailAddress(emailInput)) {
      setEmails(prevState => [{ ...emailInitialState, email: emailInput }, ...prevState]);
      setEmailInput('');
    }
  };
  const handleRemoveEmail = email => {
    setChanges(true);
    setEmails(prevState => prevState.filter(item => item.email !== email));
  };
  const handleEmailInputKeyDown = e => {
    if (e.key === 'Enter') {
      handleAddEmail();
    }
  };

  const handleUpdateEmail = (email, notificationType) => {
    setChanges(true);
    setEmails(prevState => {
      return prevState.map(item => {
        if (item.email === email) {
          return { ...item, [notificationType]: !item[notificationType] };
        }

        return item;
      });
    });
  };

  return (
    <div className="brand-notifications">
      <Prompt when={changes} message={handleBlockedNavigation} />
      {unsavedChangesModal && (
        <UnsavedChangesModal
          cancel={async () => {
            await saveChanges();
            setUnsavedChangesModal(false);
          }}
          action={() =>
            handleProceedAndDiscardChanges(unsavedChangesModal.action, unsavedChangesModal.location)
          }
        />
      )}
      <Container width={500}>
        <Row
          style={{
            color: '#ffffff',
            fontFamily: 'system-ui',
            lineHeight: '26px',
          }}
        >
          <p>
            Do you want someone on your team to get notified about Trend updates?
            <br />
            Add additional email addresses to each category below to make sure your project is
            always taken care of.
          </p>
        </Row>
        <Row>
          <div className="notifications-input-group">
            <input
              className="notifications-input"
              defaultValue=""
              onChange={handleInputEmail}
              onKeyDown={handleEmailInputKeyDown}
              placeholder="Add email"
              type="email"
              value={emailInput}
            />
            <button onClick={handleAddEmail} type="button">
              Add Email
            </button>
          </div>
        </Row>
        {emails.length > 0 &&
          emails.map((email, index) => (
            <Row
              key={`email-notifications-${index.toString()}`}
              className="notifications-emails-list"
            >
              <Col span={12}>
                <EmailButton
                  authEmail={authEmail}
                  email={email.email}
                  handleClick={handleRemoveEmail}
                />
              </Col>
              <Col span={12}>
                <div className="notifications-checkbox-group">
                  <Col span={3}>
                    <Checkbox
                      active={email.contentApprovals}
                      handleClick={() => handleUpdateEmail(email.email, 'contentApprovals')}
                    />
                  </Col>
                  <Col span={21}>
                    <span>New Content to Approve</span>
                  </Col>
                </div>
                <div className="notifications-checkbox-group">
                  <Col span={3}>
                    <Checkbox
                      active={email.newApplications}
                      handleClick={() => handleUpdateEmail(email.email, 'newApplications')}
                    />
                  </Col>
                  <Col span={21}>
                    <span>10+ New Applications</span>
                  </Col>
                </div>
              </Col>
            </Row>
          ))}
        <div className="notifications-floating-footer">
          <button
            className={`notifications-update-button ${
              loading ? 'notifications-update-button--loading' : ''
            }`}
            onClick={saveChanges}
            type="button"
            disabled={loading}
          >
            {loading && (
              <i className="anticon anticon-loading">
                <svg
                  viewBox="0 0 1024 1024"
                  focusable="false"
                  className="anticon-spin"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                >
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
                </svg>
              </i>
            )}
            <span>Save Updates</span>
          </button>
        </div>
      </Container>
    </div>
  );
};

const UnsavedChangesModal = ({ action, cancel }) => (
  <Modal visible closable={false} className="back-modal" footer={null} width="100%">
    <div className="modal-text-container">
      <h2 className="type-sfpro-regular black mb-2 ht-6 lh-2 size-28px" style={{ fontWeight: 800 }}>
        You have unsaved changes.
      </h2>
      <div
        className="type-sfpro-regular text-black size-16px lh-20px"
        style={{ marginTop: '0.75rem', fontWeight: 400, width: '320px' }}
      >
        Do you want to save your changes or do you want to discard your current changes?
      </div>
      <div
        className="modal-button-container"
        style={{
          flexDirection: 'row',
          height: 'auto',
        }}
      >
        <Button
          key="approve"
          className="secondary"
          onClick={action}
          block
          style={{ marginRight: '1.25rem', borderWidth: '3px' }}
        >
          Don't save changes
        </Button>
        <Button type="primary" key="cancel" onClick={cancel} block>
          Save changes
        </Button>
      </div>
    </div>
  </Modal>
);

export default withRouter(Notifications);
