import React from 'react';
import { useCheckSession } from 'components/Session';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import { ReactComponent as StarIcon } from 'images/ContentShowcase/ContentShowcaseTabIcons/StarIcon.svg';

const ContentShowcaseTab = ({ isActive }) => {
  const hasSession = useCheckSession();
  const classname = isActive ? 'showcase-tab active' : 'showcase-tab';

  const showcaseLink = hasSession ? '/campaigns/showcase' : '/showcase';

  return (
    <div className={classname}>
      <Link to={showcaseLink} className="showcase-link">
        <span className="link-wrapper">
          <Icon component={StarIcon} className="sidenav-icon-with-box" />
          <span className="tab-link-text">UGC Showcase</span>
        </span>
      </Link>
    </div>
  );
};

export default ContentShowcaseTab;
