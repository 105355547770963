import React from 'react';
import ShowcaseVideo from './ShowcaseVideo';

/**
 *
 * @typedef {{
 *  showModal: () => any,
 *  item: any
 * }} ContentCardProps
 * @type {React.FC<ContentCardProps>}
 */

export const ContentCard = React.memo(({ showModal, item }) => {
  const { mediaUrl, creatorHandle, type, profilePictureUrl, mediaThumbnail, styleType } = item;

  return (
    <div className="content-card">
      <button
        className="u-button-reset content-card-inner"
        onClick={() => showModal(mediaUrl, creatorHandle, type, profilePictureUrl, styleType)}
      >
        {type === 'image' ? (
          <img className="content-img" alt={creatorHandle} src={mediaThumbnail} />
        ) : (
          <ShowcaseVideo noPlay={false} src={item.previewUrl} extra={mediaThumbnail} />
        )}
        <figure className="creator-figure">
          <img className="creator-avatar" alt={creatorHandle} src={profilePictureUrl} />
          <figcaption className="creator-name">{creatorHandle}</figcaption>
        </figure>
      </button>
    </div>
  );
});
