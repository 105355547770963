import React from 'react';

const SoonaShowcase = () => {
  return (
    <div className="soona-showcase-page">
      <iframe title="soona Showcase" src={`${process.env.REACT_APP_NEXT_APP_URL}/soona/showcase`} />
    </div>
  );
};

export default SoonaShowcase;
