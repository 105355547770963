import React from 'react';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { useSession } from 'components/Session';

/**
 * Influencer Avatar Component
 *
 * @typedef {{
 * influencerUID: string,
 * showWorkBefore: boolean,
 * onClick: () => void
 * }} InfluencerAvatarProps
 * @type {React.FC<InfluencerAvatarProps>}
 */
const InfluencerAvatar = ({ influencerUID, showWorkBefore, onClick }) => {
  const influencer = useSelector(selectors.getInfluencer(influencerUID));
  const { completedPartnershipInfluencerUIDs } = useSession();

  const influencerName = influencer ? influencer.name : 'unknown';
  const influencerState = influencer ? influencer.state : 'unknown';
  return (
    <div className="influencer-avatar" role="presentation" onClick={onClick}>
      <div className="influencer-avatar-image">
        <Avatar src={influencer ? influencer.image : 'https://via.placeholder.com/150'} size={50} />
      </div>
      <div className="text-left influencer-info">
        <p className="influencer-info-username">{influencerName}</p>
        <p className="influencer-info-state">{influencerState}</p>
      </div>
      {showWorkBefore && completedPartnershipInfluencerUIDs.includes(influencerUID) && (
        <p className="status-tag">Worked together before</p>
      )}
    </div>
  );
};

export default InfluencerAvatar;
