import axios from 'axios';
import { getAccessToken } from 'utils/cookies';

export const patchUpdateBrand = async updatePayload => {
  const url = `${process.env.REACT_APP_TREND_API_URL}/brand/update`;

  const { data } = await axios.patch(
    url,
    { ...updatePayload },
    { headers: { Authorization: `Bearer ${getAccessToken()}` } }
  );

  return data;
};

export const postCreateStripeCustomer = async () => {
  const url = `${process.env.REACT_APP_TREND_API_URL}/stripe/create-customer`;

  const { data } = await axios.post(
    url,
    {},
    { headers: { Authorization: `Bearer ${getAccessToken()}` } }
  );

  return data.stripeCustomerId;
};

export async function favoriteCreator(brandId, payload) {
  const url = `${process.env.REACT_APP_TREND_API_URL}/brand/${brandId}/favorite-creator`;

  const { data } = await axios.post(url, payload, {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  });

  return data;
}

export async function unFavoriteCreator(brandId, payload) {
  const url = `${process.env.REACT_APP_TREND_API_URL}/brand/${brandId}/unfavorite-creator`;

  const { data } = await axios.post(url, payload, {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  });

  return data;
}

export const postBrandSendMessage = async ({
  conversationUID,
  /**
   * creatorId,
   * text,
   * type,
   * url = '',
   * thumbnail = '',
   * messageId
   */
  ...payload
}) => {
  const url = `${process.env.REACT_APP_TREND_API_URL}/message/brand/${conversationUID}/send`;

  const { data } = await axios.post(url, payload, {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  });

  return data;
};

export const postBrandReadMessage = async ({ messageThreadId }) => {
  const url = `${process.env.REACT_APP_TREND_API_URL}/message/brand/${messageThreadId}/read`;

  const { data } = await axios.post(url, null, {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  });

  return data;
};

/**
 *
 * @param {AbortSignal} [signal]
 * @return {Promise<*>}
 */
export async function hubspotIdentify(signal) {
  const url = `${process.env.REACT_APP_TREND_API_URL}/brand/hubspot/identification`;

  const { data } = await axios.post(url, null, {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
    signal,
  });

  return data;
}

export async function patchBrandViewApproveInvites() {
  const url = `${process.env.REACT_APP_TREND_API_URL}/partnership/view-approve-invites`;

  const { data } = await axios.patch(
    url,
    {},
    {
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    }
  );

  return data;
}

export async function patchBrandReviewInfluencer(reviewId, body) {
  const url = `${process.env.REACT_APP_TREND_API_URL}/review/${reviewId}/brand-review-influencer`;

  const { data } = await axios.patch(url, body, {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  });

  return data;
}
