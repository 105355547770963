import React, { useEffect, useState } from 'react';
import {
  Modal as AntModal,
  Button,
  Row,
  Col,
  Form,
  Input,
  Select,
  Radio,
  Avatar,
  notification,
  Icon,
} from 'antd';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { useSession } from 'components/Session';
import { useFirebase } from 'lib/Firebase';
import { calculatePartnershipCreditCost } from 'utils/content';
import AwaitingModal from './AwaitingModal';

/**
 * @param {import('types').Product} product
 */
const contentBreakdown = product => {
  const { type, postTypes = [], isStoryRequired, styleCategory = [] } = product;

  const contents = [];
  if (type === 'social') {
    const images = postTypes.filter(postType => postType === 'image');
    const videos = postTypes.filter(postType => postType === 'video');
    if (images.length > 0) {
      contents.push(`${images.length} image post`);
    }
    if (videos.length > 0) {
      contents.push(`${videos.length} video post`);
    }

    if (isStoryRequired) {
      contents.push('1 story post');
    }
  } else {
    contents.push(`${styleCategory.length} ${type}`);
  }

  return contents.join(', ');
};

/**
 * Rehire Influencer Invitation Modal Component
 *
 * @typedef {{
 * onSubmit: (values: any) => Promise<any>,
 * show: boolean, onClose: () => any,
 * form: import("antd/lib/form/Form").WrappedFormUtils
 * influencerUID: string,
 * }} ModalProps
 * @type {React.FC<ModalProps>}
 */
const InvitationModal = ({ influencerUID, show, onClose, form, onSubmit }) => {
  const firebase = useFirebase();
  const influencer = useSelector(selectors.getInfluencer(influencerUID)) || {};

  const brandCreditCost = calculatePartnershipCreditCost(influencer);
  const { username, image } = influencer;
  const products = useSelector(selectors.getProducts());
  const [fetchingValidIds, setFetchingValidIds] = useState(false);
  const {
    activeProductUIDs = [],
    brand: { uid: brandUID },
  } = useSession();
  const [validProductUIDs, setValidProductUIDs] = useState([]);

  const [awaitingModal, setAwaitingModal] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const {
    resetFields,
    getFieldDecorator,
    getFieldValue,
    validateFieldsAndScroll,
    setFieldsValue,
  } = form;
  const requireShipping = getFieldValue('rehireWillBrandSendProduct');

  async function checkValidIds() {
    setFetchingValidIds(true);
    // Gets existing partnerships between the brand and influencer
    const partnershipDocs = await firebase.firestore
      .collection('influencersPartnerships')
      .where('brandUID', '==', brandUID)
      .where('influencerUID', '==', influencerUID)
      .get();

    const existingCreatorCampaigns = partnershipDocs.docs.map(doc => doc.data().productUID);

    // Valid IDs are active products where the influencer is not in an active partnership
    const validIds = activeProductUIDs.filter(
      productUID => !existingCreatorCampaigns.includes(productUID)
    );
    setValidProductUIDs(validIds);
    setFieldsValue({ productUID: validIds[0] });
    setFetchingValidIds(false);
  }

  useEffect(() => {
    checkValidIds();
  }, [influencerUID]);

  function handleSubmit() {
    validateFieldsAndScroll(async (err, fields) => {
      setSubmitting(true);
      if (!err) {
        try {
          await onSubmit({
            influencerUID,
            ...fields,
          });
          resetFields();
          onClose();
          setAwaitingModal(true);
        } catch (e) {
          const feedbackMessage =
            String(e.response.data.message) || String(e.message) || 'Something went wrong';
          notification.error({
            message: 'Error',
            description: feedbackMessage,
          });
        }
      }
      setSubmitting(false);
    });
  }

  function handleAwaitingClose() {
    setAwaitingModal(false);
  }

  function handleFilterOptions(input, option) {
    return option.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  return (
    <div>
      <AntModal
        closable={false}
        className="rehire-modal"
        visible={show}
        onCancel={onClose}
        footer={false}
        width={742}
        style={{ top: 20 }}
      >
        <Button className="close" onClick={onClose}>
          <Icon component={CloseIcon} />
        </Button>
        <Row type="flex" justify="center">
          <div className="user-avatar">
            <Avatar src={image} shape="circle" size={90} />
          </div>
        </Row>
        <h4 className="text-center" style={{ marginBottom: '2px' }}>
          @{username}
        </h4>
        <h2 className="text-center" style={{ marginBottom: '0', lineHeight: '26px' }}>
          Work with this creator again!
        </h2>
        <Row type="flex" justify="center">
          <div className="credit-cost-rehire">
            <h2
              className="text-center"
              style={{
                fontSize: '42px',
                lineHeight: '26px',
                marginBottom: '12px',
                marginTop: '24px',
              }}
            >
              {brandCreditCost}
            </h2>
            <h5 className="text-center" style={{ fontSize: '16px' }}>
              Creator Credit Cost
            </h5>
          </div>
        </Row>
        <Row type="flex" justify="center">
          <Col style={{ width: 448 }}>
            <Form form={form} layout="vertical" hideRequiredMark>
              <Form.Item label="Choose a Campaign">
                {getFieldDecorator('productUID', {
                  rules: [{ required: true, message: 'Required' }],
                })(
                  <Select
                    dropdownClassName="trend-select"
                    showSearch
                    loading={fetchingValidIds}
                    placeholder={
                      <div>
                        <div>Select a Campaign</div>
                        <div className="rehire-selector-details"> -- </div>
                      </div>
                    }
                    filterOption={handleFilterOptions}
                  >
                    {validProductUIDs.map(productUID => {
                      return (
                        <Select.Option key={productUID} value={productUID}>
                          <div className="rehire-selector-name">{products[productUID].name}</div>
                          <span className="rehire-selector-details">
                            {contentBreakdown(products[productUID])}
                          </span>
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="What type of content are you looking for this time?">
                {getFieldDecorator('rehireContentNeeds', {
                  rules: [
                    { max: 500, min: 20, message: 'Please use between 20 and 500 characters.' },
                    {
                      required: true,
                      message: 'Please let the creator know what you are looking for.',
                    },
                  ],
                })(<Input.TextArea placeholder="Add Description..." />)}
              </Form.Item>
              <Form.Item
                className="shipping"
                label={
                  <div>
                    Will you send a product for this next project?
                    <p>
                      The likelyhood of a creator accepting another job from you increases when they
                      recieve products again. If you have a particularly high-value product, it may
                      not be necessary to send an additional product.
                    </p>
                  </div>
                }
              >
                {getFieldDecorator('rehireWillBrandSendProduct', {
                  initialValue: false,
                  rules: [{ required: true, message: 'Required' }],
                })(
                  <Radio.Group>
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
              {requireShipping && (
                <Form.Item label="What will you ship?">
                  {getFieldDecorator('rehireProductDescription', {
                    rules: [
                      { max: 500, min: 10, message: 'Please use at least 10 characters.' },
                      {
                        required: true,
                        message: 'Tell the creators what you will send them.',
                      },
                    ],
                  })(<Input placeholder="Add Description..." />)}
                </Form.Item>
              )}
            </Form>

            <div className="footer">
              <Button
                loading={submitting}
                className="submit-btn"
                type="primary"
                onClick={handleSubmit}
              >
                Send Rehire Request
              </Button>
              <Row type="flex" justify="center">
                <p>
                  This creator will have 72 hours to accept or decline your offer. Use the messages
                  tab to entice them to take the deal!
                </p>
              </Row>
            </div>
          </Col>
        </Row>
      </AntModal>

      <AwaitingModal show={awaitingModal} onClose={handleAwaitingClose} />
    </div>
  );
};

export default Form.create({ name: 'form' })(InvitationModal);
