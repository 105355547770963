import Promise from 'bluebird';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Button, Row, Col, Tag } from 'antd';
import moment from 'moment';
import formatSocialData, { formatSocialDataMin } from 'components/Helpers/numeral';
import Container from 'components/Common/Container';
import Influencer, {
  AdminInfluencerFilters,
  InfluencerTable,
  Badge,
  InfluencerActiveCampaigns,
  InfluencerAdminDrawer,
  useInfluencerDrawer,
  SuspendButton,
  InfluencersStatistics,
  EditLevelsButton,
  UnsuspendButton,
} from 'components/Influencers';
import { influencersSearch, firebase } from 'lib/Firebase';
import InfluencerWrapper from 'wrappers/influencerWrapper';
import { actions, selectors } from 'stores';
import OverdueCampaigns from 'components/Influencers/OverdueCampaigns';

const columns = [
  {
    title: 'Influencer',
    dataIndex: 'influencerUID',
    key: 'influencerUID',
    className: 'username',
    render: val => <Influencer.Avatar showName influencerUID={val} />,
  },
  {
    title: 'Last login',
    dataIndex: 'lastLoginDate',
    className: 'last-login-date',
    render: val => {
      if (val) {
        const date = moment(val * 1000);
        return (
          <div>
            {date.format('M/D/YY')}
            <br />
            {date.format('h:mma')}
          </div>
        );
      }
      return '';
    },
    sorter: true,
  },
  {
    title: 'Joined',
    dataIndex: 'createdAt',
    className: 'joined',
    render: val => moment(val * 1000).format('M/D/YY'),
    sorter: true,
  },
  {
    title: 'Suspension',
    dataIndex: 'isSuspended',
    className: 'is-suspended',
    render: val => <Tag color={val ? 'red' : 'green'}>{val ? 'Suspended' : 'Not suspended'}</Tag>,
  },
  {
    title: 'Overdue Partnerships',
    dataIndex: 'overduePartnershipUIDs',
    className: 'overduePartnershipUIDs',
    render: val => {
      return (
        <Popover
          arrowPointAtCenter
          trigger="hover"
          placement="bottomRight"
          content={<OverdueCampaigns overduePartnershipUIDs={val} />}
        >
          <div className="active-partnerships">{val ? val.length : 0}</div>
        </Popover>
      );
    },
  },
  {
    title: 'Rating',
    dataIndex: 'engagementRate',
    className: 'rating',
    render: (val, { avgAttitudeProfessionalism, avgContentQuality }) =>
      formatSocialDataMin((avgContentQuality + avgAttitudeProfessionalism) / 2),
  },
  {
    title: 'Followers',
    dataIndex: 'followedByCount',
    className: 'followers',
    render: formatSocialData,
  },
  { title: 'Apps', dataIndex: 'applicationCount', className: 'apps' },
  {
    title: 'Last App Date',
    dataIndex: 'lastAppliedDate',
    className: 'last-applied-date',
    render: val => (val === 0 ? '--' : moment(val * 1000).format('M/D/YY')),
  },
  {
    title: 'Active',
    dataIndex: 'activePartnershipCount',
    className: 'active',
    render: (val, { key }) => {
      return (
        <Popover
          arrowPointAtCenter
          trigger="hover"
          placement="bottomRight"
          content={<InfluencerActiveCampaigns influencerUID={key} />}
        >
          <div className="active-partnerships">{val}</div>
        </Popover>
      );
    },
  },
  {
    title: 'Complete',
    dataIndex: 'completedPartnershipCount',
    className: 'complete',
    render: val => {
      return <div className="completed-partnerships">{val}</div>;
    },
  },
  { title: 'Level', dataIndex: 'star', className: 'level', render: val => <Badge level={val} /> },
];

const initialPagination = { page: 1, limit: 10 };

const searchInfluencer = async (filters, limit, lastDoc) => {
  let query = influencersSearch(filters, true);

  if (!filters.id) {
    query = query.limit(limit);
  }

  if (lastDoc) {
    query = query.startAt(lastDoc);
  }

  const result = await query.get();

  return Array.isArray(result.docs) ? result : [result];
};

/**
 * Admin V2 Influencers Tab
 *
 * @type {React.FC}
 */
const InfluencersPage = () => {
  const dispatch = useDispatch();
  const brands = useSelector(selectors.getBrands());
  const products = useSelector(selectors.getProducts());
  const [influencerDrawer, handleShow, handleHide] = useInfluencerDrawer();
  const [influencerUIDs, setInfluencerUIDs] = useState([]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(initialPagination);
  const [lastInfluencerDoc, setLastInfluencerDoc] = useState();
  const [lastLoginDateSorter, setLastLoginDateSorter] = useState();

  async function retrieveInfluencers(newFilters = {}, lastDoc) {
    if (!lastDoc) {
      setInfluencerUIDs([]);
    }

    let newLastDoc;
    const influencers = {};
    const influencerDocs = await searchInfluencer(newFilters, pagination.limit, lastDoc);
    const sortedInfluencerUIDs = [];
    influencerDocs.forEach(doc => {
      const influencer = doc.data();

      influencers[doc.id] = influencer;
      sortedInfluencerUIDs.push(doc.id);
      newLastDoc = doc;
    });

    const partnerships = {};
    const newBrands = {};
    const newProducts = {};

    await Promise.map(Object.keys(influencers), async influencerUID => {
      const partnershipDocs = await firebase.firestore
        .collection('influencersPartnerships')
        .where('influencerUID', '==', influencerUID)
        .where('status', 'not-in', ['rejected', 'submitted'])
        .get();

      partnershipDocs.forEach(doc => {
        const partnership = doc.data();
        const { brandUID, productUID } = partnership;

        partnerships[doc.id] = { uid: doc.id, ...partnership };
        if (!brands[brandUID]) {
          newBrands[brandUID] = {};
        }

        if (!products[productUID]) {
          newProducts[productUID] = {};
        }

        dispatch(
          actions.entities.influencersPartnerships.addInfluencerPartnerships(influencerUID, {
            [doc.id]: partnership,
          })
        );
      });
    });

    const brandPromises = Promise.map(Object.keys(newBrands), async brandUID => {
      const brandDoc = await firebase.firestore
        .collection('brands')
        .doc(brandUID)
        .get();
      newBrands[brandUID] = brandDoc.data();
    });

    const productPromises = Promise.map(Object.keys(newProducts), async productUID => {
      const productDoc = await firebase.firestore
        .collection('products')
        .doc(productUID)
        .get();
      newProducts[productUID] = productDoc.data();
    });

    await Promise.all([brandPromises, productPromises]);

    dispatch(actions.entities.products.addProducts(newProducts));
    dispatch(actions.entities.brands.addBrands(newBrands));
    dispatch(actions.entities.influencers.addInfluencers(influencers));
    dispatch(actions.entities.partnerships.addPartnerships(partnerships));

    setInfluencerUIDs(x => [...new Set([...x, ...sortedInfluencerUIDs])]);
    setLastInfluencerDoc(newLastDoc);
  }

  useEffect(() => {
    retrieveInfluencers(filters);
  }, []);

  function loadMore() {
    retrieveInfluencers(filters, lastInfluencerDoc);
    setPagination({
      ...pagination,
      page: pagination.page + 1,
    });
  }

  function handleChange(newFilters) {
    retrieveInfluencers(newFilters);
    setFilters(newFilters);
    setPagination(initialPagination);
  }

  async function handleSuspend() {
    retrieveInfluencers(filters);
    handleHide();
  }

  async function handleUnsuspend() {
    retrieveInfluencers(filters);
    handleHide();
  }

  async function handleDownload() {
    const influencers = await influencersSearch(filters).get();
    let csvContent = '';

    influencers.docs.forEach(doc => {
      const {
        details: { authEmail = '', completeAddress, name, username },
      } = new InfluencerWrapper({ details: doc.data() });
      const row = [`"${name}"`, `"${username}"`, `"${authEmail}"`, `"${completeAddress}"`].join(
        ','
      );
      csvContent += `${row}\r\n`;
    });
    const csv = new Blob([csvContent], { type: 'text/CSV' });
    const url = URL.createObjectURL(csv);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'influencer-info.csv');
    link.click();
  }

  async function handleInfuencersDownload() {
    const influencers = await influencersSearch(filters)
      .orderBy('lastLoginDate', 'desc')
      .get();
    let csvContent = `Name,Username,Auth Email,State,Last Login,Joined,Rating,Followers,Apps,Last App Date,Active,Complete,Level\r\n`;
    let sortedInfluencers = influencers.docs;

    if (sortedInfluencers) {
      sortedInfluencers = influencers.docs.sort((a, b) => {
        if (lastLoginDateSorter === 'ascend') {
          return moment(a.lastLoginDate, 'YYYY-MM-DD HH:mm:SS ZZ') >
            moment(b.lastLoginDate, 'YYYY-MM-DD HH:mm:SS ZZ')
            ? 1
            : -1;
        }
        if (lastLoginDateSorter === 'descend') {
          return moment(b.lastLoginDate, 'YYYY-MM-DD HH:mm:SS ZZ') >
            moment(a.lastLoginDate, 'YYYY-MM-DD HH:mm:SS ZZ')
            ? 1
            : -1;
        }
        return 0;
      });
    }

    sortedInfluencers.forEach(doc => {
      const {
        details: {
          authEmail = '',
          name,
          username,
          state = '',
          lastLoginDate,
          createdAt,
          avgContentQuality = 0,
          avgAttitudeProfessionalism = 0,
          followedByCount = 0,
          applicationCount = 0,
          lastAppliedDate,
          activePartnershipCount = 0,
          completedPartnershipCount = 0,
          star = 0,
        },
      } = new InfluencerWrapper({ details: doc.data() });
      const row = [
        `"${name}"`,
        `"${username}"`,
        `"${authEmail}"`,
        `"${state}"`,
        `"${lastLoginDate ? moment(lastLoginDate * 1000).format('M/D/YY h:mma') : ''}"`,
        `"${moment(createdAt, 'YYYY-MM-DD HH:mm:SS ZZ').format('M/D/YY')}"`,
        `"${formatSocialDataMin((avgContentQuality + avgAttitudeProfessionalism) / 2)}"`,
        `"${formatSocialData(followedByCount)}"`,
        `"${applicationCount}"`,
        `"${lastAppliedDate === 0 ? '--' : moment(lastAppliedDate * 1000).format('M/D/YY')}"`,
        `"${activePartnershipCount}"`,
        `"${completedPartnershipCount}"`,
        `"${star}"`,
      ].join(',');
      csvContent += `${row}\r\n`;
    });

    const csv = new Blob([csvContent], { type: 'text/CSV' });
    const url = URL.createObjectURL(csv);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'influencers.csv');
    link.click();
  }

  const showLimit = pagination.limit * pagination.page - pagination.page;

  const listUIDs = influencerUIDs.slice(0, showLimit);

  return (
    <div id="admin-influencer-page">
      <Container width={940}>
        <InfluencersStatistics />
      </Container>
      <Container width={940}>
        <Row type="flex" justify="space-between" className="search-filters">
          <AdminInfluencerFilters
            onChange={handleChange}
            onDownload={handleDownload}
            onInfluencersDownload={handleInfuencersDownload}
          />
        </Row>
        {influencerUIDs.length > 0 && (
          <>
            <Row>
              <Col>
                <InfluencerTable
                  influencerUIDs={listUIDs}
                  columns={columns}
                  onRow={({ key }) => ({
                    onClick: () => {
                      handleShow(key);
                    },
                  })}
                  sortDirections={['descend', 'ascend']}
                  setLastLoginDateSorter={setLastLoginDateSorter}
                />
              </Col>
            </Row>
            <div className="text-center">
              {influencerUIDs.length > showLimit && influencerUIDs.length !== 0 && (
                <Button className="load-more" onClick={loadMore}>
                  LOAD MORE
                </Button>
              )}
            </div>
            <InfluencerAdminDrawer
              {...influencerDrawer}
              onHide={handleHide}
              actionButtons={(influencerUID, isSuspended) => [
                <EditLevelsButton influencerUID={influencerUID} />,
                isSuspended ? (
                  <SuspendButton influencerUID={influencerUID} onSuspend={handleSuspend} />
                ) : (
                  <UnsuspendButton influencerUID={influencerUID} onUnsuspend={handleUnsuspend} />
                ),
              ]}
            />
          </>
        )}
      </Container>
    </div>
  );
};

export default InfluencersPage;
