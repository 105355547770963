import React, { useState } from 'react';
import { ReactComponent as PlusIcon } from 'images/ContentShowcase/DrawerButton.svg';

/**
 *
 * @typedef {{
 *  question: string,
 *  answer: string,
 * }} FAQCardProps
 * @type {React.FC<FAQCardProps>}
 */

const FAQCard = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleDrawer() {
    setIsOpen(!isOpen);
  }

  const drawerClass = isOpen ? 'answer-drawer open' : 'answer-drawer';
  const iconClass = isOpen ? 'plus-icon open' : 'plus-icon';
  return (
    <div className="faq-card">
      <ul>
        <li>
          <button className="u-button-reset faq-toggle-btn" onClick={() => toggleDrawer()}>
            <span>{question}</span>
            <PlusIcon className={iconClass} style={{ height: '1.5rem', width: '1.5rem' }} />
          </button>
        </li>
        <li className={drawerClass}>{answer}</li>
      </ul>
    </div>
  );
};

export default FAQCard;
