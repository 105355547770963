import { notification } from 'antd';
import EmptyApplications from 'components/Common/EmptyApplications';
import { PrefetchCreatorProfilePages } from 'components/Influencers/Drawer/CreatorProfileDrawer';
import Product, { useProductData } from 'components/Products';
import { brandRejectApplication } from 'lib/Firebase/callables/brands';
import { brandApproveInfluencer } from 'api/partnerships';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'stores';
import mixpanel from '../../../lib/Analytics/Mixpanel';

/**
 * Campaign Page: Applications
 *
 * @type {React.FC}
 */
const Application = () => {
  const dispatch = useDispatch();

  const productData = useProductData();
  const { productUID } = productData;
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState();
  const [modalDetails, setModalDetails] = useState();

  const partnerships = useSelector(selectors.getPartnerships());
  const influencers = useSelector(selectors.getInfluencers());

  const applications = useSelector(selectors.getProductApplications(productUID));
  const hasInfluencers = applications.length > 0;

  function handleShowApprovalModal(partnershipUID) {
    setSelectedApplication(partnershipUID);
    const { influencerUID, type } = partnerships[partnershipUID];
    const influencer = influencers[influencerUID];
    setModalDetails({ influencer, type });
    setShowApproveModal(true);
  }

  async function handleApprove() {
    try {
      notification.info({ message: 'Approving Creator', duration: 4 });
      const updatedPartnership = await brandApproveInfluencer(productUID, selectedApplication);

      dispatch(
        actions.productContexts.addProductApprovedPartnerships(productUID, {
          [selectedApplication]: updatedPartnership,
        })
      );
      notification.success({
        message: 'Approved',
        description: 'Creator successfully approved!',
        duration: 2,
      });
    } catch (e) {
      const feedbackMessage =
        String(e.response.data.message) ||
        String(e.message) ||
        'Something went wrong on accepting creator';
      notification.error({
        message: 'Error',
        description: feedbackMessage,
        duration: 4,
      });
    }

    setShowApproveModal(false);
  }

  function handleShowRejectModal(partnershipUID) {
    setSelectedApplication(partnershipUID);
    setShowRejectModal(true);
  }

  async function handleReject(reason, block = false) {
    try {
      notification.info({ message: 'Removing Application', duration: 4 });
      await brandRejectApplication(selectedApplication, block);
      notification.success({
        message: 'Rejected',
        description: 'Creator successfully rejected!',
        duration: 2,
      });
    } catch (e) {
      const feedbackMessage =
        String(e.response.data.message) ||
        String(e.message) ||
        'Something went wrong on Rejecting creator';
      notification.error({
        message: 'error',
        description: feedbackMessage,
        duration: 4,
      });
    }
    setShowRejectModal(false);
  }

  async function handleViewProfile(partnershipUID) {
    const { influencerUID, brandUID } = partnerships[partnershipUID];
    const { creatorUsername, creatorId } = influencers[influencerUID];
    const profileLink = `${process.env.REACT_APP_CREATOR_WEB_URL}/profile/${creatorUsername ||
      creatorId}`;

    if (mixpanel) {
      mixpanel.track('Brand Reviews Creator Application', {
        campaignId: productUID,
        creatorId: influencerUID,
        brandId: brandUID,
      });
    }

    window.open(profileLink, '_blank');
  }

  return (
    <div id="application-page">
      <div style={{ background: '#ffffff' }}>
        {!hasInfluencers && <EmptyApplications />}
        {hasInfluencers && (
          <>
            <Product.Applications
              data={applications}
              approveCallback={handleShowApprovalModal}
              rejectCallback={handleShowRejectModal}
              viewProfileCallback={handleViewProfile}
            />
            <Product.ApproveApplicationModal
              show={showApproveModal}
              modalDetails={modalDetails}
              onSubmit={handleApprove}
              onHide={() => setShowApproveModal(false)}
            />
            <Product.RejectApplicationModal
              show={showRejectModal}
              onSubmit={handleReject}
              onHide={() => setShowRejectModal(false)}
            />
            <PrefetchCreatorProfilePages
              influencerUIDs={applications.map(({ influencerUID }) => influencerUID)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Application;
