import { Menu } from 'antd';
import * as routes from 'constants/routes';
import React from 'react';
import { useLocation, Link } from 'react-router-dom';

/**
 * Account Edit Tabs
 *
 * @typedef {{}} TabsProps
 * @type {React.FC<TabsProps>}
 */
const Tabs = () => {
  const location = useLocation();
  const path = location.pathname.split('account/');
  const selectedKeys = path[1];

  return (
    <Menu
      mode="horizontal"
      className="account-menu"
      selectedKeys={[selectedKeys]}
      defaultOpenKeys={['store/edit']}
    >
      <Menu.Item key="email-notifications">
        <Link to={routes.ACCOUNT_EMAIL_NOTIFICATIONS_PATH} replace>
          Email Notifications
        </Link>
      </Menu.Item>
      <Menu.Item key="company">
        <Link to={routes.ACCOUNT_EDIT_PATH} replace>
          Company
        </Link>
      </Menu.Item>
      <Menu.Item key="privacy">
        <a href="https://soona.co/privacy-policy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </Menu.Item>
      <Menu.Item key="tos">
        <a href="https://soona.co/terms" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
      </Menu.Item>
    </Menu>
  );
};

export default Tabs;
