import React from 'react';
import { Icon, Modal as AntModal, Button, Row } from 'antd';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';
import ThumbsUp from 'images/components/RehireInfluencer/thumbup.png';

/**
 * Rehire Influencer Awaiting Modal Component
 *
 * @typedef {{ show: boolean, onClose: () => any}} ModalProps
 * @type {React.FC<ModalProps>}
 */
const AwaitingModal = ({ show, onClose }) => {
  return (
    <AntModal
      closable={false}
      className="rehire-modal awaiting"
      visible={show}
      onCancel={onClose}
      footer={false}
      width={742}
    >
      <Button className="close" onClick={onClose}>
        <Icon component={CloseIcon} />
      </Button>
      <Row type="flex" justify="center">
        <img className="banner" alt="Awaiting" src={ThumbsUp} />
      </Row>
      <Row type="flex" justify="center">
        <h1>Invitation Sent - Awaiting Creator Response</h1>
        <p>
          The creator have 72 hours to respond to your request. If they accept your request they’ll
          execute the project! Ambassadors allow you to create an authentic long term connections
          and in the case of social posts, their audiences. Looking to learn more?{' '}
          <a href="https://support.soona.co/trend-ugc" target="_blank" rel="noopener noreferrer">
            Click here
          </a>
        </p>
      </Row>
      <Row type="flex" justify="center" className="footer">
        <Button className="submit-btn" onClick={onClose}>
          Return to Dashboard
        </Button>
      </Row>
    </AntModal>
  );
};

export default AwaitingModal;
