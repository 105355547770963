import React, { useState } from 'react';
import { Row, Col, Select } from 'antd';
import Brands from 'components/Brands';
import Influencer from 'components/Influencers';
import { CREATOR_CATEGORIES } from 'constants/creator';

/**
 * Creator Content Admin Filters Component
 *
 * @typedef {{ onChange: () => any}} AdminFiltersProps
 * @type {React.FC<AdminFiltersProps>}
 */
const AdminFilters = ({ onChange }) => {
  const [, setFilters] = useState({
    brand: null,
    creator: null,
    creatorLevel: null,
    category: null,
    type: null,
    styleCategory: null,
  });
  const categories = Object.keys(CREATOR_CATEGORIES);

  const styleCategories = {
    all: 'All',
    unboxingExplanationVideo: 'Unboxing Explanation Video',
    productReviewOrTestimonialVideo: 'Product Review or Testimonial Video',
    actionOrProductInUseVideo: 'Action or Product in Use Video',
    lifestyleOrActionPhotography: 'Lifestyle or Action Photography',
    productPhotography: 'Product Photography',
    portraitStylePhotography: 'Portrait Style Photography',
  };

  function handleChange(filterName) {
    return value => {
      setFilters(filterState => {
        const newFilters = { ...filterState, [filterName]: value };
        onChange(newFilters);
        return newFilters;
      });
    };
  }

  return (
    <Row type="flex" justify="start" gutter={8}>
      <Col md={6}>
        <Row className="search" type="flex" align="middle" style={{ gap: '8px' }}>
          <Brands.AdminSearch onChange={val => handleChange('brand')(val.search)} />
          <Influencer.AdminSearch onChange={handleChange('influencer')} />
        </Row>
      </Col>

      <Col span={4} md={4} className="filters-combo">
        <label>Creator Level</label>
        <Select
          allowClear
          showArrow={false}
          className="trend-select"
          placeholder="All"
          style={{ width: '100%' }}
          onChange={handleChange('creatorLevel')}
        >
          <Select.Option value="all">All</Select.Option>
          <Select.Option value="1">1</Select.Option>
          <Select.Option value="2">2</Select.Option>
          <Select.Option value="3">3</Select.Option>
        </Select>
      </Col>

      <Col span={4} md={4} className="filters-combo">
        <label>Content Type</label>
        <Select
          allowClear
          showArrow={false}
          className="trend-select"
          placeholder="All"
          style={{ width: '100%' }}
          onChange={handleChange('type')}
        >
          <Select.Option value="all">All</Select.Option>
          <Select.Option value="image">Image</Select.Option>
          <Select.Option value="video">Video</Select.Option>
        </Select>
      </Col>

      <Col span={4} md={4} className="filters-combo">
        <label>Creative Type</label>
        <Select
          allowClear
          showArrow={false}
          className="trend-select"
          placeholder="All"
          style={{ width: '100%' }}
          onChange={handleChange('styleCategory')}
        >
          {Object.entries(styleCategories).map(([category, label]) => (
            <Select.Option key={category} value={category}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col span={4} md={4} className="filters-combo">
        <label>Project Category</label>
        <Select
          allowClear
          showArrow={false}
          className="trend-select"
          placeholder="All"
          style={{ width: '100%' }}
          onChange={handleChange('projectCategory')}
        >
          {categories.map(category => (
            <Select.Option key={category} value={category}>
              {CREATOR_CATEGORIES[category]}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default AdminFilters;
