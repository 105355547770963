import React from 'react';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import { ReactComponent as SoonaIcon } from 'images/soona/wink.svg';
import * as routes from 'constants/routes';

const SoonaShowcaseTab = () => {
  const isActive = window.location.pathname === routes.SOONA_SHOWCASE;
  const classname = isActive ? 'showcase-tab active' : 'showcase-tab';

  return (
    <div className={classname}>
      <Link to={routes.SOONA_SHOWCASE} className="showcase-link">
        <span className="link-wrapper">
          <span className="flex-row-between">
            <span className="sidenav-link-left">
              <Icon component={SoonaIcon} className="sidenav-icon-with-box" />
              <p className="tab-link-text">soona studio</p>
            </span>
            <span className="new-pill" style={{ marginRight: 16 }}>
              NEW
            </span>
          </span>
        </span>
      </Link>
    </div>
  );
};

export default SoonaShowcaseTab;
