import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconBack } from 'images/icon-back-arrow-white.svg';

export const SettingsBackButton = () => {
  const history = useHistory();

  return (
    <div className="action-link clickable">
      <div className="back">
        <button
          onClick={() => history.goBack()}
          style={{
            background: 'transparent',
            border: 'none',
            color: '#ffb917',
            cursor: 'pointer',
          }}
        >
          <IconBack />
          <span className="white d-block type-sbold bsize-4">Back</span>
        </button>
      </div>
    </div>
  );
};
