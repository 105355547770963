import { Avatar, List, Row } from 'antd';
import MinLayout from 'components/Common/MinLayout';
import { useSession } from 'components/Session';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectors } from 'stores';
import * as routes from 'constants/routes';

/**
 * History Page
 *
 * @type {React.FC<HistoryPageProps>}
 */
const CampaignHistoryPage = () => {
  const products = useSelector(selectors.getProducts());
  const { completedProductUIDs } = useSession();

  return (
    <Row type="flex" justify="space-around" style={{ paddingTop: '38px' }}>
      <MinLayout>
        <h1> Campaign History </h1>
        <List
          dataSource={completedProductUIDs}
          header="Archived Campaigns"
          itemLayout="horizontal"
          bordered
          renderItem={productUID => {
            const product = products[productUID];

            return (
              <List.Item
                actions={[
                  <Link className="ant-btn trend-btn" to={routes.toCampaignSummaryPath(productUID)}>
                    View
                  </Link>,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar src={product.image} size="large" />}
                  title={product.name}
                  description={product.description}
                />
              </List.Item>
            );
          }}
        />
      </MinLayout>
    </Row>
  );
};

export default CampaignHistoryPage;
