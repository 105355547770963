import React from 'react';
import urlRegex from 'url-regex';

const GUIDELINE_LABELS = [
  'Which products will you send the creators?',
  'Content Style Request',
  'Caption Details',
];

const oldGuide = guidelines => (
  <div>
    <div className="text-black text-description" style={{ whiteSpace: 'pre-line' }}>
      {guidelines}
    </div>
  </div>
);

const urlify = text => {
  return text.replace(urlRegex({ strict: false }), url => {
    let tmpUrl = url;

    if (!/https:\/\/|http:\/\//.test(url)) {
      tmpUrl = `https://${url}`;
    }

    return `<a href="${tmpUrl}" target="_blank">${url}</a>`;
  });
};

const newGuide = (postGuidelines = []) =>
  postGuidelines.map((guidelines, index) => (
    <div key={GUIDELINE_LABELS[index]} style={{ marginTop: index === 0 ? 0 : '29px' }}>
      <div className="header-text">{GUIDELINE_LABELS[index]}</div>
      <div
        className="text-black text-description"
        style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
      >
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: urlify(guidelines),
          }}
        />
      </div>
    </div>
  ));

/**
 * Post Guideline Component
 *
 * @typedef {{ product: import('components/Session/Context').Product}} PostGuidelinesProps
 * @type {React.FC<PostGuidelinesProps>}
 */
const PostGuidelines = ({ product }) => {
  const { postGuidelines, guidelines } = product;
  const finalGuideline = postGuidelines || guidelines;

  return (
    <div className="post-guidelines">
      {Array.isArray(finalGuideline) ? newGuide(finalGuideline) : oldGuide(finalGuideline)}
    </div>
  );
};

export default PostGuidelines;
