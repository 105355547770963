import React, { useState } from 'react';
import { Alert, Layout, Row } from 'antd';
import Logo from 'components/Common/Logo';
import { useSession } from 'components/Session';
import MinLayout from 'components/Common/MinLayout';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import Review from 'components/Review';
import PromoBanner from 'components/Common/PromoBanner';
import { ReactComponent as TrendLogoWebLightMode } from 'images/trend-logo-web-light-mode.svg';
import AddCreditNotification from './SideNav/AddCreditNotification';
import ContentCredit from './SideNav/ContentCredit';
import SideMenus from './SideNav/SideMenus/SideMenus';
import ReturnButton from './ReturnButton';
import ContentShowcase from '../../pages/Campaigns/ContentShowcase';

const { Sider, Content } = Layout;

/**
 * @typedef {{
 * firebase: import('lib/Firebase').firebase
 * }} Props
 *
 * @extends {React.Component<Props>}
 */
const SiderLayout = ({ children }) => {
  const today = moment();
  const bannerDeadline = moment('2024-09-03');
  const bannerExtendedDeadline = moment('2024-09-07');

  const [cookies] = useCookies(['returnToken']);
  const { brand, activeProductUIDs: activeProducts } = useSession();
  const products = useSelector(selectors.getProducts());
  const campaignById = products;
  const ownedCampaigns = activeProducts;

  const adminAlert = () => (
    <Row type="flex" justify="space-around">
      <MinLayout>
        <ReturnButton /> You are in admin mode! Logged in as &nbsp;
        <strong>{brand.name}</strong>.
      </MinLayout>
    </Row>
  );

  const {
    standardCredits = 0,
    addedStandardCredits = 0,
    status,
    lastPurchaseDate,
    approvedAt,
    uid: brandUID,
  } = brand;

  const { returnToken } = cookies;
  const tmpApprovedAt = approvedAt;
  const firstCampaign = campaignById[ownedCampaigns[0]];
  const firstCampaignCompleted = firstCampaign;
  const onBoarding = status && !lastPurchaseDate;
  const onBoardingApproved = onBoarding && status === 'approved';
  const onBoardingApprovedComplete = onBoardingApproved && firstCampaignCompleted;
  const showMarqueNotif = status === 'approved' && !lastPurchaseDate;

  let approvedAtDisplay =
    firstCampaign && firstCampaign.extendedTrialDate ? firstCampaign.extendedTrialDate : approvedAt;

  if (!approvedAtDisplay) {
    approvedAtDisplay =
      firstCampaign && firstCampaign.extendedTrialDate
        ? firstCampaign.extendedTrialDate
        : tmpApprovedAt;
  }

  const expiredOnBoardingCampaign =
    onBoardingApprovedComplete && moment.unix(approvedAtDisplay).add(24, 'hours') < moment();

  const extendedOnBoardingCampaign =
    onBoardingApprovedComplete &&
    firstCampaign &&
    firstCampaign.extendedTrialDate &&
    moment(firstCampaign.extendedTrialDate * 1000).add(24, 'hours') > moment();

  const showContentShowcase = window && window.location.pathname === '/campaigns/showcase';

  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const sidenavWrapperClassNames = isNavExpanded
    ? 'sidenav-wrapper sidenav-wrapper--visible'
    : 'sidenav-wrapper';
  const contentLayoutClassNames =
    today <= bannerExtendedDeadline ? 'header-offset header-offset--with-banner' : 'header-offset';

  function toggleNavigation() {
    setIsNavExpanded(!isNavExpanded);

    if (!isNavExpanded) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }

  return (
    <>
      <header className="platform-header">
        <button
          type="button"
          className="nav-toggle"
          aria-controls="navigation"
          aria-expanded={isNavExpanded}
          aria-label="toggle the navigation menu"
          onClick={toggleNavigation}
        >
          <span />
        </button>
        <TrendLogoWebLightMode className="header-logo" />
      </header>
      <PromoBanner
        promoDeadline={bannerDeadline.format('Y-MM-DD')}
        extendedDeadline={bannerExtendedDeadline.format('Y-MM-DD')}
      />
      <Layout>
        <Sider id="navigation" className={sidenavWrapperClassNames}>
          <div className="sidenav-header">
            <Logo className="sidenav-logo" />
            <ContentCredit standardCredits={standardCredits + addedStandardCredits} />
          </div>
          <SideMenus
            showContentShowcase={showContentShowcase}
            onNavigate={() => setIsNavExpanded(false)}
          />
        </Sider>

        <Layout id="trend-content-layout" className={contentLayoutClassNames}>
          <Content>
            {returnToken && (
              <Alert
                className="admin-warning"
                description={adminAlert(brand)}
                type="warning"
                style={{ paddingLeft: '110px' }}
              />
            )}
            {showContentShowcase ? <ContentShowcase /> : children}
          </Content>
        </Layout>
        {!returnToken && <Review.PendingModal brandUID={brandUID} />}

        {showMarqueNotif && (
          <AddCreditNotification
            unixDate={approvedAtDisplay}
            expired={expiredOnBoardingCampaign && !extendedOnBoardingCampaign}
          />
        )}
      </Layout>
    </>
  );
};

export default SiderLayout;
