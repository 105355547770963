import React from 'react';
import { Menu } from 'antd';
import Container from 'components/Common/Container';
import { useSession } from 'components/Session';
import { useHistory } from 'react-router-dom';

/**
 * Admin Layout Nav
 *
 * @type {React.FC}
 */
const AdminNav = () => {
  const history = useHistory();
  let path = history.location.pathname;
  path = path.substring(path.lastIndexOf('/') + 1);
  const {
    brand: { isAdmin },
  } = useSession();

  function goTo(p) {
    history.push(`/adminv2/${p}`);
  }

  return (
    <div className="page-header admin-nav">
      <Container width={940}>
        <h1>Trend Brands</h1>
        {isAdmin && (
          <Menu mode="horizontal" selectedKeys={[path]}>
            <Menu.Item key="influencers" onClick={() => goTo('influencers')} tabIndex={0}>
              Database
            </Menu.Item>
            <Menu.Item key="activeCampaigns" onClick={() => goTo('activeCampaigns')} tabIndex={0}>
              Active Campaigns
            </Menu.Item>
            <Menu.Item key="contents" onClick={() => goTo('contents')} tabIndex={0}>
              Content
            </Menu.Item>
            <Menu.Item key="reviews" onClick={() => goTo('reviews')} tabIndex={0}>
              Reviews
            </Menu.Item>
            <Menu.Item key="referrals" onClick={() => goTo('referrals')} tabIndex={0}>
              Referrals
            </Menu.Item>
            <Menu.Item key="processing" onClick={() => goTo('payouts')} tabIndex={0}>
              Payouts
            </Menu.Item>
            <Menu.Item key="onboardingGuides" onClick={() => goTo('onboardingGuides')} tabIndex={0}>
              Onboarding Guides
            </Menu.Item>
            <Menu.Item key="businessApproval" onClick={() => goTo('businessApproval')} tabIndex={0}>
              Businesses for approval
            </Menu.Item>
            <Menu.Item key="expiredCredits" onClick={() => goTo('expiredCredits')} tabIndex={0}>
              Expired Credits
            </Menu.Item>
            <Menu.Item key="pendingInfluencers" onClick={() => goTo('pendingInfluencers')} tabIndex={0}>
              Pending Influencers
            </Menu.Item>
            <Menu.Item key="subscriptions" onClick={() => goTo('subscriptions')} tabIndex={0}>
              Subscriptions
            </Menu.Item>
            <Menu.Item key="apiQueues" onClick={() => goTo('apiQueues')} tabIndex={0}>
              API Queues
            </Menu.Item>
          </Menu>
        )}
      </Container>
    </div>
  );
};

export default AdminNav;
