import React from 'react';

/**
 * @typedef {{
 * partnershipUID: stirng,
 * approveCallback: (partnershipUID: string) => void,
 * rejectCallback: (partnershipUID: string) => void
 * }} ApplicationActionsProps
 * @type {React.FC<ApplicationActionsProps>}
 */
const ApplicationActions = ({ approveCallback, rejectCallback }) => {
  return (
    <div className="cell-action">
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }}>
        <div
          role="presentation"
          style={{
            cursor: 'pointer',
            width: '94px',
            borderRadius: '15px',
            textAlign: 'center',
            height: '25px',
            lineHeight: '23px',
            backgroundColor: '#FFB917',
          }}
          className="link"
          onClick={approveCallback}
        >
          <span
            style={{ color: '#000', letterSpacing: '0.5px', fontSize: '12px', fontWeight: 'bold' }}
          >
            approve
          </span>
        </div>

        <div
          role="presentation"
          style={{
            cursor: 'pointer',
            width: '94px',
            borderRadius: '15px',
            textAlign: 'center',
            height: '25px',
            lineHeight: '23px',
            border: '1px solid #A9B0B9',
          }}
          className="link"
          onClick={rejectCallback}
        >
          <span
            style={{
              color: '#5B6572',
              letterSpacing: '0.5px',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          >
            reject
          </span>
        </div>
      </div>
    </div>
  );
};

export default ApplicationActions;
