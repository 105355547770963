import { Spin, Row, Col, Icon } from 'antd';
import React, { createRef, useEffect, useState } from 'react';
import { ReactComponent as PlayButtonSVG } from 'images/ContentShowcase/playIcon.svg';

/**
 * Video Component
 *
 * @typedef {{
 *    autoPlay?: boolean,
 *    extra: string,
 *    src: string
 * }} VideoProps
 * @type {React.FC<VideoProps>}
 */
const ShowcaseVideo = ({ autoPlay, extra, src }) => {
  const vidRef = createRef();

  const [loaded, setLoaded] = useState({ [src]: false });

  useEffect(() => {
    if (loaded && vidRef) {
      vidRef.current.addEventListener('ended', event => {
        // eslint-disable-next-line no-param-reassign
        event.target.currentTime = 0;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vidRef]);

  return (
    <>
      <div className="video-thumbnail">
        <Row justify="center" align="middle" type="flex" className="video-player-actions">
          <Col>
            <Icon component={PlayButtonSVG} style={{ width: '100%' }} />
          </Col>
        </Row>
        <img className="content image" alt="video thumbnail" src={extra} />
      </div>
      <div
        className="video-loader"
        onMouseEnter={() => vidRef.current.play()}
        onMouseLeave={() => vidRef.current.pause()}
      >
        {loaded[src] && (
          <Row justify="center" align="middle" type="flex" className="video-player-actions">
            <Col>
              <Icon component={PlayButtonSVG} style={{ width: '100%' }} />
            </Col>
          </Row>
        )}
        <video
          key={src}
          ref={vidRef}
          preload="auto"
          onLoadedData={() => {
            setLoaded({ [src]: true });
          }}
          autoPlay={autoPlay}
        >
          <source src={src} type="video/mp4" />
          <source src={src} type="video/webm" />
          <source src={src} type="video/ogg" />
          <source src={src} type="video/avi" />
          Sorry, your browser doesn’t support embedded videos.
        </video>
        {!loaded[src] && <Spin />}
      </div>
    </>
  );
};

export default ShowcaseVideo;
