import React from 'react';
import { Row } from 'antd';
import Container from 'components/Common/Container';
import { PrefetchCreatorProfilePages } from 'components/Influencers/Drawer/CreatorProfileDrawer';
import { useProductData } from 'components/Products';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { useCampaignContents } from 'api/content';
import { toContentCollection } from 'constants/routes';
import ApprovedContentTable from './ApprovedContentTable/ApprovedContentTable';
import ApprovalCard from './ApprovalCard/ApprovalCard';

/**
 * Campaign Post Approval Page
 *
 * @type {React.FC}
 */
const Approvals = () => {
  /** State */
  const { invitedInfluencerUIDs, productUID } = useProductData();
  const { data, isLoading } = useCampaignContents(productUID);
  const influencers = useSelector(selectors.getInfluencers());
  const { params } = useRouteMatch();

  /** Action Handlers */
  function handleViewProfile(influencerUID) {
    const { creatorUsername, creatorId } = influencers[influencerUID];
    const profileLink = `${process.env.REACT_APP_CREATOR_WEB_URL}/profile/${creatorUsername ||
      creatorId}`;

    window.open(profileLink, '_blank');
  }

  function onApprovalCardClick(creatorId) {
    toContentCollection(params.campaignId, creatorId);
  }

  if (isLoading || !data) {
    return <></>;
  }

  const { submissions, approvals } = data;
  const displaySubmissions = submissions.sort((a, b) => {
    if (a.status !== b.status) {
      return a.status === 'pending' ? -1 : 1;
    }
    if (a.approvalAt && b.approvalAt) {
      return a.approvalAt - b.approvalAt;
    }
    if (a.approvalAt || b.approvalAt) {
      return a.approvalAt ? -1 : 1;
    }
    return a.submittedAt - b.submittedAt;
  });

  return (
    <>
      <Row id="influencers-page">
        <div id="creator-content-approval-content">
          <div
            className="submitted-contents"
            style={{ overflowX: submissions.length > 0 ? 'scroll' : 'hidden' }}
          >
            <Container width={742}>
              {displaySubmissions.length === 0 ? (
                <p className="empty">NO NEW CONTENT TO APPROVE</p>
              ) : (
                <div style={{ minWidth: '100%', width: (219 + 20) * displaySubmissions.length }}>
                  {displaySubmissions.map(
                    ({ creatorId, previewContent, numNewContent, approvalAt, status }) => (
                      <ApprovalCard
                        key={creatorId}
                        creatorId={creatorId}
                        previewContent={previewContent}
                        numNewContent={numNewContent}
                        approvalAt={approvalAt}
                        status={status}
                        onClick={() => onApprovalCardClick(creatorId)}
                      />
                    )
                  )}
                </div>
              )}
            </Container>
          </div>
          <div className="page-header">
            <Container width={742}>
              <h2>Content Approval</h2>
              <p>
                Creators work hard to represent your brand and provide you with relatable content
                you need to grow through your social channels, websites, and paid ads. Once you
                approve this content it's yours to keep for ever with 100% usage rights. Make sure
                you communicate with creators BEFORE they shoot content to make sure you're aligned
                on the shoot.
              </p>
            </Container>
          </div>
          <ApprovedContentTable
            approvals={approvals}
            handleViewProfile={handleViewProfile}
            onApprovalCardClick={onApprovalCardClick}
          />
          <PrefetchCreatorProfilePages influencerUIDs={invitedInfluencerUIDs} />
        </div>
      </Row>
    </>
  );
};

export default Approvals;
