import React, { useState } from 'react';
import { Row, Icon, AutoComplete } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';

const { Option } = AutoComplete;
/**
 * Brand Admin Search Component
 *
 * @typedef {{ onChange }} AdminSearchProps
 * @type {React.FC<AdminSearchProps>}
 */
const AdminSearch = ({ onChange }) => {
  const [currentSearch, setCurrentSearch] = useState('');
  const [options, setOptions] = useState([]);
  const brands = useSelector(selectors.getBrands());
  const brandSearches = useSelector(selectors.getAdminBrands());

  function handleChange(value) {
    if (onChange) {
      onChange({ search: value });
      setCurrentSearch(value);
    }
  }

  function handleSearch(value = '') {
    const data = brandSearches.filter(x => {
      const { name = '', authEmail = '' } = brands[x] || {};
      return (
        name.toLowerCase().includes(value.toLowerCase()) ||
        authEmail.toLowerCase().includes(value.toLowerCase())
      );
    });

    setOptions(data);
  }

  const list = options.map(x => (
    <Option key={x} value={x}>
      {brands[x].name}
    </Option>
  ));

  return (
    <Row className="search" type="flex" align="middle" style={{ flexWrap: 'nowrap' }}>
      <Icon type="search" style={{ marginRight: '5px' }} />
      <AutoComplete
        allowClear
        onSearch={handleSearch}
        onSelect={handleChange}
        onChange={val => !val && currentSearch && handleChange(null)}
        placeholder="Search brands"
      >
        {list}
      </AutoComplete>
    </Row>
  );
};

export default AdminSearch;
